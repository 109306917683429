import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/",
    name: "LargeScreen",
    component: () => import("../views/LargeScreen/index.vue"),
  },
  {
    path: "/LargeScreens",
    name: "LargeScreens",
    component: () => import("../views/LargeScreens/index.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  routes: routes,
});

export default router;
