import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/reset.css'
import '@/assets/css/common.less'
import 'amfe-flexible'
import * as Echarts from 'echarts'
import scroll from 'vue-seamless-scroll'
import Title from '@/components/Title/index.vue'
Vue.component('Title', Title)
Vue.use(ElementUI)
Vue.use(scroll)
Vue.config.productionTip = false
Vue.prototype.$echarts = Echarts
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
