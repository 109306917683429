<template>
  <div  :style="{'--lineWidth':lineWidth+'px'}" class="title">
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    lineWidth: {
      type: Number,
      default: 119
    }
  }
}
</script>

<style lang="less" scoped>
.title {
    font-size: 18px;
    color: #33BDFC;
    text-indent: 9px;
    font-weight: 600;

    &:after {
      display: block;
      content: "";
      width: var(--lineWidth);
      height: 3px;
      background: #2473EF;
      opacity: 0.8;
      margin-top: 5px;
    }
  }
</style>
